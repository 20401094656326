<!-- 订单中心 -->
<template>
  <div id="capital">
    <van-nav-bar :title="$t('m_mime.zjpx')" left-arrow @click-left="onClickLeft">
      <template #right>
        <i class="iconfont icon-shaixuanguolv"></i>
      </template>
    </van-nav-bar>
    <div class="noOrder">
      <div class="order">
        <img src="../../../assets/img/notPage.png" alt="" />
        <p>{{$t('m_annoucements.zwsj')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";
.van-nav-bar {
  background-image: linear-gradient(0deg, #ceab77 10%, #eddbc1 100%) !important;
}

.van-nav-bar__right {
  .iconfont {
    font-size: 0.5rem;
    color: #38373b !important;
  }
}

/deep/.van-nav-bar__title {
  color: #38373b !important;
}
/deep/.van-nav-bar .van-icon {
  color: #38373b !important;
}

#capital {
  width: 100%;
  height: 100%;
  position: relative;
  .noOrder {
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
    background-color: #222;
    .order {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        width: 275px;
      }
      p {
        color: #666;
        font-size: 15px;
      }
    }
  }
}
</style>